<template>
  <v-layout column>
    <v-layout column v-if="progressInvisible">
      <AgreementWarningMessage v-if="!owner" :patient="patient"></AgreementWarningMessage>
      <SessionTabs :value.sync="currentTab" :sessions="sessions"></SessionTabs>
      <v-tabs-items touchless v-model="currentTab">
        <v-tab-item v-for="(session, index) in sessions" :key="`${index}${session.session_type}`">
          <component
            :is="patientCard"
            :session.sync="sessions[index]"
            :patient="patient"
            :hideCloneShfs="sessions.length <= 1"
            :loading="loading"
            :owner="owner"
            :isSessionTypeActive="isSessionTypeActive"
            @predefined="onPredefined"
            @append="onAppend"
            @clone="onClone"
            @change="onChange"
            @new="onNew"
            @copy="onCopy"
            @uploaded="onUploaded"
            @success="handleSuccess"
            @error="handleError"
          ></component>
        </v-tab-item>
      </v-tabs-items>
    </v-layout>
    <v-layout v-else align-center column class="mt-4">
      <v-progress-circular
        indeterminate
        color="primary"
        width="7"
        size="60"
        class="ma-3"
      ></v-progress-circular>
      <DefaultTitle class="ma-3">Wczytuję dane...</DefaultTitle>
    </v-layout>
  </v-layout>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";

const PHYSIOTHERAPEUTIC = 0;
const UROGYNECOLOGICAL = 1;
const PEDIATRIC = 2;

export default {
  mixins: [ProxyCRUDMixin,],
  props: {
    patient: {},
    owner: {},
    sessions: {},
    sessionType: {},
    activeTab: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false
    },
    isSessionTypeActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentTab: {
      get() {
        return this.activeTab;
      },
      set(value) {
        this.$emit("update:activeTab", value);
      },
    },
    isSpectatorMode() {
      return !!this.owner;
    },
    progressInvisible() {
      return this.sessions.length > 0;
    },
    patientCard() {
      switch (this.sessionType) {
        case PHYSIOTHERAPEUTIC:
          return "PhysiotherapeuticCard";
        case UROGYNECOLOGICAL:
          return "UrogynecologicalCard";
        case PEDIATRIC:
          return "PediatricCard";
        default:
          return "";
      }
    },
  },
  components: {
    DefaultTitle: () => import("@/components/text/DefaultTitle"),
    AgreementWarningMessage: () =>
      import("@/components/controls/patientcard/AgreementWarningMessage"),
    SessionTabs: () => import("@/components/tabs/patientcard/SessionTabs"),
    PhysiotherapeuticCard: () =>
      import("@/components/cards/patientcard/PhysiotherapeuticCard"),
    UrogynecologicalCard: () =>
      import("@/components/cards/patientcard/UrogynecologicalCard"),
    PediatricCard: () => import("@/components/cards/patientcard/PediatricCard"),
  },
  methods: {
    onPredefined(data) {
      this.$emit("predefined", data);
    },
    onAppend(data) {
      this.$emit("append", data);
    },
    onClone(data) {
      this.$emit("clone", data);
    },
    onNew() {
      this.$emit("new");
    },
    onChange(data) {
      this.$emit("change", data);
    },
    onUploaded(value) {
      this.$emit("uploaded", value);
    },
    onCopy() {
      this.$emit("copy");
    }
  }
};
</script>